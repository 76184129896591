<template>
  <div class="ai-buddy-button-rounded font-outfit-regular">
    <i
      class="ib-icon ib-icon-magic-2 cursor-pointer"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  name: 'AiBuddyButtonRounded'
}
</script>

<style scoped lang="scss">
.ai-buddy-button-rounded {
  background: $color-ai-light;
  border: 2px solid $color-ai-light;
  border-radius: 50%;
  transition: all 0.25s;

  &:hover {
    border: 2px solid $color-ai;
  }

  .ib-icon {
    font-size: 22px;
    padding: 8px;
    color: $color-ai;
  }
}

</style>
