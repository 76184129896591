<template>

  <!-- Idea -->
  <div id="story-mode-idea-filled">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">
            <!-- Dashboard Header -->

            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('pages.businessGuide.idea.title')"
              :guide-visible="guideVisible"
              :completed-step-name="'ideaCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Idea"
            help-me-write
            type="idea"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <div v-if="loaders.main" class="why-and-what-loader">
          <loader />
        </div>

        <div v-else>
          <!-- Business plan additional info -->
          <business-plan-additional-info
            completed-step-name="ideaCompleted"
            section-name="Idea"
            show-ai-buddy
            @ai-buddy-open="aiBuddyDrawerVisible = true"
          />
          <!-- Business plan additional info -->

          <!-- Header -->
          <business-guide-header class="mt-4 mt-md-0">
            <template #left>
              <p class="text-lead xl m-0 my-md-4 o-100">
                {{ $t('pages.businessGuide.idea.subtitle') }}
              </p>
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
            </template>
          </business-guide-header>
          <!-- /Header -->

          <!-- Why And What Wrapper -->
          <div class="why-what-cards-wrapper">
            <el-row :gutter="20" class="d-flex flex-wrap">

              <!-- The Why Question Box -->
              <el-col :md="12" class="d-flex mb-3 mb-md-0">

                <!-- Idea Why Question -->
                <idea-question
                  class="w-100"
                  :type="'why'"
                  :title="whySection.title"
                  :description="whySection.description"
                  :text="why"
                  :matching="!!what"
                  @click="dialogAddWhyAnswerVisible = true"
                />
                <!-- /Idea Why Question -->

                <!-- Dialog Add Why Problem -->
                <dialog-idea-add-problem
                  v-if="why !== null"
                  :dialog-type="'why'"
                  :text="why"
                  :dialog-idea-add-problem-visible="dialogAddWhyAnswerVisible"
                  :title="whySection.title"
                  :description="whySection.description"
                  @on-save="updateWhy"
                  @discard="dialogAddWhyAnswerVisible = false"
                />
                <!-- /Dialog Add Why Problem -->

              </el-col>
              <!-- /The Why Question Box -->

              <!-- The What Question Box -->
              <el-col :md="12" class="d-flex">

                <!-- Idea What Question -->
                <idea-question
                  class="w-100"
                  :type="'what'"
                  :title="whatSection.title"
                  :description="whatSection.description"
                  :text="what"
                  :matching="!!why"
                  @click="dialogAddWhatAnswerVisible = true"
                />
                <!-- /Idea What Question -->

                <!-- Dialog Add What Problem -->
                <dialog-idea-add-problem
                  v-if="what !== null"
                  :dialog-type="'what'"
                  :text="what"
                  :dialog-idea-add-problem-visible="dialogAddWhatAnswerVisible"
                  :title="whatSection.title"
                  :description="whatSection.description"
                  @on-save="updateWhat"
                  @discard="dialogAddWhatAnswerVisible = false"
                />
                <!-- /Dialog Add What Problem -->

              </el-col>
              <!-- /The What Question Box -->

            </el-row>
          </div>
          <!-- /Why And What Wrapper -->
        </div>

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'concept'"
        :step="'idea'"
        :concept-name="'ideaMoreDetails'"
        :completed-step-name="'ideaCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'ideaCompleted'"
      :step-filled="!!why && !!what"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="ideaExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="loaders.removeExamples"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Idea -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogIdeaAddProblem from './Dialogs/DialogIdeaAddProblem'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IdeaQuestion from './IdeaQuestion'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'

import { mapActions, mapGetters } from 'vuex'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Idea',
  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddMoreDetails,
    BusinessGuideHeader,
    DashboardHeader,
    DialogIdeaAddProblem,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    IdeaQuestion,
    Loader,
    MainBottomBar,
    MainNavigation
  },

  mixins: [
    MixinGuide,
    MixinProcessCompleted,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      dialogRemoveExamplesVisible: false,
      dialogAddWhyAnswerVisible: false,
      dialogAddWhatAnswerVisible: false,
      dialogProcessCompletedVisible: false,
      guideVisible: false,
      loaders: {
        main: false,
        removeExamples: false
      },
      removeExamplesLoading: false,
      backNavigation: {
        disabled: true
      }
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getIdeaWhy',
      'getIdeaWhat',
      'isIdeaWhyFromTemplate',
      'isIdeaWhatFromTemplate',
      'getStepIsCompleted'
    ]),
    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.product'),
        route: 'business-guide-concept-product'
      }
    },
    whySection () {
      return {
        title: this.$t('pages.businessGuide.idea.whySection.title'),
        description: this.$t('pages.businessGuide.idea.whySection.description')
      }
    },
    whatSection () {
      return {
        title: this.$t('pages.businessGuide.idea.whatSection.title'),
        description: this.$t('pages.businessGuide.idea.whatSection.description')
      }
    },
    why () {
      return this.getIdeaWhy
    },

    what () {
      return this.getIdeaWhat
    },

    hasItemsFromTemplate () {
      return this.isIdeaWhatFromTemplate || this.isIdeaWhyFromTemplate
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('ideaCompleted')
    },

    removeButtonVisible () {
      return this.canEdit && this.hasItemsFromTemplate
    }
  },

  async created () {
    this.loaders.main = true
    await this.getIdea()
    this.loaders.main = false
    this.openGuide('ideaGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getIdea',
      'deleteIdeaExamples'
    ]),

    deleteExamples () {
      this.loaders.removeExamples = true
      Promise.all([
        this.deleteIdeaExamples('idea-what'),
        this.deleteIdeaExamples('idea-why')
      ]).then(response => {
        response.forEach(item => {
          const { type, ids } = item
          this.deleteFromState({ type, ids })
        })
        this.dialogRemoveExamplesVisible = false
        this.loaders.removeExamples = false
      }).catch(_ => { this.loaders.removeExamples = false })
    },

    updateWhy (text) {
      this.$nextTick(() => {
        this.scrollableWhyCard()
      })
    },

    updateWhat (text) {
      this.$nextTick(() => {
        this.scrollableWhatCard()
      })
    },

    scrollableWhyCard () {
      const whyCardText = document.querySelector('.why .clamped')
      const whyCardTextInner = document.querySelector('.why .clamped .inner-clamped')

      if (whyCardText) {
        if ((whyCardTextInner.offsetHeight - 20) > whyCardText.offsetHeight) {
          whyCardText.classList.add('scrollable')
        } else {
          whyCardText.classList.remove('scrollable')
        }
      }
    },

    scrollableWhatCard () {
      const whatCardText = document.querySelector('.what .clamped')
      const whatCardTextInner = document.querySelector('.what .clamped .inner-clamped')

      if (whatCardText) {
        if ((whatCardTextInner.offsetHeight - 20) > whatCardText.offsetHeight) {
          whatCardText.classList.add('scrollable')
        } else {
          whatCardText.classList.remove('scrollable')
        }
      }
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    }
  }
}
</script>

<style scoped>
#story-mode-idea-filled {
  margin-left: 0;
}
</style>
