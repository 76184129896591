var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    active:
      _vm.stepData['name'] === _vm.activeStep ||
      _vm.categoryItemNotSelected ||
      (_vm.stepData['name'] !== _vm.activeStep && _vm.categoryItemNotSelected),
    'active-no-child-selected':
      _vm.stepData['name'] === _vm.activeStep && !_vm.categoryItemNotSelected,
    'nav-category': !_vm.withoutNavClass
  },on:{"click":_vm.handleClick,"mouseenter":_vm.toggleStep,"mouseleave":function($event){return _vm.$emit('toggle-step', '')}}},[_c('div',{staticClass:"category-wrapper",class:{
      completed:
        _vm.completedStepsCount === _vm.stepData.items.filter(step => step.enabled === true).length && _vm.stepData.items.filter(step => step.enabled === true).length > 0
    }},[_c('p',{staticClass:"category-title"},[_vm._v(" "+_vm._s(_vm.stepData.title)+" "),_c('svg',{class:'caret-icon',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"292.362","height":"292.362","viewBox":"0 0 292.362 292.362","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"}})])]),_c('span',{staticClass:"category-count",class:{
        hidden:
          _vm.completedStepsCount === _vm.stepData.items.length ||
          _vm.showCounter === false
      }},[_vm._v(" "+_vm._s(_vm.completedStepsCount)+"/"+_vm._s(_vm.stepData.items.filter(step => step.enabled === true).length)+" ")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.stepData['name'] === _vm.activeStep)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.awaySettings),expression:"awaySettings"}],staticClass:"category-dropdown",on:{"mouseleave":function($event){return _vm.$emit('toggle-step', '')}}},[_vm._l((_vm.stepData.items.filter(
          stepItem => stepItem.enabled === true
        )),function(item){return _c('router-link',{key:item.name,attrs:{"to":{ name: `business-guide-${_vm.stepData.name}-${item.name}` }}},[_c('div',{staticClass:"category-item",class:{
            active:
              _vm.$route.name === `business-guide-${_vm.stepData.name}-${item.name}`
          }},[_c('div',{staticClass:"left"},[_c('i',{staticClass:"ib-icon h2 mr-2",class:item.icon}),_c('p',{staticClass:"item-title ml-1"},[_vm._v(" "+_vm._s(item.title)+" ")])]),(item.completedStatus)?_c('div',{staticClass:"pseudo-checkbox",class:{
              checked:
                _vm.$store.state.idea.storyMode.common.completedSteps[
                  item.completedStepName
                ]
            }},[_c('i',{staticClass:"el-icon-check"})]):_vm._e()])])}),_vm._l((_vm.stepData.items.filter(
          stepItem => stepItem.enabled === false
        )),function(item){return _c('a',{key:item.name,staticClass:"disabled-link"},[_c('div',{staticClass:"category-item",class:{
            active:
              _vm.$route.name === `business-guide-${_vm.stepData.name}-${item.name}`
          }},[_c('div',{staticClass:"left"},[_c('i',{staticClass:"ib-icon h2 mr-2",class:item.icon}),_c('p',{staticClass:"item-title ml-1 is-disabled"},[_vm._v(" "+_vm._s(item.title)+" "),_c('span',{staticClass:"soon"},[_vm._v("soon")])])]),(item.completedStatus)?_c('div',{staticClass:"pseudo-checkbox",class:{
              checked:
                _vm.$store.state.idea.storyMode.common.completedSteps[
                  item.completedStepName
                ]
            }},[_c('i',{staticClass:"el-icon-check"})]):_vm._e()])])})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }