<template>
  <!-- Dialog Add Problem -->
  <ib-dialog
    id="dialog-story-mode-add-problem"
    :visible="dialogIdeaAddProblemVisible"
    fullscreen
    empty-header
    @open="onOpen"
    @close="close"
  >
    <ib-dialog-container max-width="740px" class="content-wrapper">

      <!-- Header -->
      <header>

        <!-- Title -->
        <h2>{{ title }}</h2>
        <!-- /Title -->

        <!-- Description -->
        <p>{{ description }}</p>
        <!-- /Description -->

      </header>
      <!-- /Header -->

      <!-- Ai Buddy -->
      <ai-buddy :type="suggestionsType" class="mb-4" />
      <!-- Ai Buddy -->

      <!-- Quill Wrapper -->
      <div class="quill-wrapper">
        <quill-editor
          ref="quillio"
          v-model="content"
          :options="editorOptions"
        />
      </div>
      <!-- /Quill Wrapper -->

      <!-- Dialog Bottom -->
      <div class="dialog-bottom">

        <!-- Divider -->
        <hr>
        <!-- /Divider -->

        <!-- Action Buttons -->
        <div class="bottom-flex justify-content-end flex-column-reverse flex-md-row">
          <!-- Close -->
          <ib-button
            variant="white"
            :size="$breakpoint.mdAndUp ? 'lg' : 'md'"
            font-family="regular"

            :min-width="$breakpoint.mdAndUp ? '230px' : '100%'"
            @click="close"
          >
            {{ $t('close') }}
          </ib-button>
          <!-- /Close -->

          <!-- Save -->
          <ib-button
            variant="blue"
            :loading="loading"
            :size="$breakpoint.mdAndUp ? 'lg' : 'md'"
            font-family="bold"
            class="mb-2 mb-md-0 ml-md-2"
            text-uppercase
            :min-width="$breakpoint.mdAndUp ? '230px' : '100%'"
            @click="onSave"
          >
            {{ $t('save') }}
          </ib-button>
          <!-- /Save -->

        </div>
        <!-- /Action Buttons -->

      </div>
      <!-- /Dialog Bottom -->
    </ib-dialog-container>
  </ib-dialog>
  <!-- /Dialog Add Problem -->
</template>

<script>
import quillPasteSmartOptions from '@/options/quillPasteSmartOptions'

import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { validateImage } from '@/helpers/imageHelper'

export default {
  name: 'DialogIdeaAddProblem',

  components: {
    AiBuddy,
    quillEditor
  },

  props: {
    dialogIdeaAddProblemVisible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    dialogType: {
      default: '',
      type: String
    }
  },

  data () {
    return {
      editorOptions: {
        modules: {
          ...quillPasteSmartOptions,
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['blockquote'],
              ['link', 'image']
            ],
            handlers: {
              image: this.imageButtonClickHandler
            }
          }
        },
        placeholder: this.$t('placeholderDescribe')
      },
      form: {
        text: this.text
      },
      loading: false
    }
  },

  computed: {
    content: {
      get () {
        return this.text
      },
      set (val) {
        this.form.text = val
      }
    },

    suggestionsType () {
      return this.dialogType === 'why' ? 'idea-why' : 'idea-what'
    }
  },

  methods: {
    ...mapActions('idea', ['uploadImage']),

    onOpen () {
      this.$router.replace({ query: { dialog: this.dialogType } })
    },

    onSave () {
      this.dialogType === 'why' ? this.saveWhy() : this.saveWhat()
    },

    saveWhy () {
      this.loading = true
      this.$http.post('story-mode/concept/idea/why/' + this.$store.state.idea.id, this.form)
        .then((response) => {
          if (response.status === 201) {
            const ideaWhy = response.data.payload
            this.$store.commit('idea/setIdeaWhy', this.form.text)
            this.$store.commit('idea/setIdeaWhyFromTemplate', !ideaWhy.isTouched && ideaWhy.isFromTemplate)
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    saveWhat () {
      this.loading = true
      this.$http.post(`story-mode/concept/idea/what/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            const ideaWhat = response.data.payload
            this.$store.commit('idea/setIdeaWhat', this.form.text)
            this.$store.commit('idea/setIdeaWhatFromTemplate', !ideaWhat.isTouched && ideaWhat.isFromTemplate)
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    close () {
      this.form.text = this.text
      this.$router.replace({ query: { } })
      this.$emit('discard')
    },

    imageButtonClickHandler () {
      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 1500) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)

        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const cEditor = this.$refs.quillio.quill
          const images = cEditor.root.getElementsByTagName('img').length

          if (!validateImage(file, images)) return

          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.uploadImage({ formData, type: 'idea-why-what' })
            .then(response => {
              // Get the current cursor position.
              const range = cEditor.getSelection()
              // Insert the image via URL where the cursor is.
              cEditor.insertEmbed(range.index, 'image', response.url)
            })
        }
      }
    }
  }
}
</script>
