<template>
  <div id="dialog-congratulations-research">

    <!-- Dialog -->
    <el-dialog :visible="dialogCongratulationsResearchVisible"
               :close-on-click-modal="true"
               :fullscreen="true"
    >

      <!-- Background Wrapper -->
      <div class="background-wrapper">

        <!-- Dialog Head -->
        <div class="dialog-head">
          <header>

            <!-- Title -->
            <h1>{{ $t('dialogs.congratulations.title') }}</h1>
            <!-- /Title -->

            <!-- Subtitle -->
            <p class="text-lead">
              <span class="strong">{{ $t('dialogs.congratulations.greatJob') }}</span> {{ $t('dialogs.congratulations.youHaveSuccessfully') }}
            </p>
            <!-- /Subtitle -->

          </header>
        </div>
        <!-- /Dialog Head -->

        <!-- Dialog Body -->
        <div class="dialog-body">
          <div class="content-wrapper">

            <!-- Illustration -->
            <img src="@/assets/img/icons/tutorials/research/research-icon.svg" alt="">
            <!-- /Illustration -->

            <!-- So what’s next? -->
            <p class="text-bold">
              {{ $t('dialogs.congratulations.researchCompleted') }}
            </p>
            <!-- /So what’s next? -->

            <!-- List -->
            <ul class="intro-list">
              <li>
                {{ $t('dialogs.congratulations.thisChapterHasHelpedYouToAnalyze') }}
              </li>
              <li>
                {{ $t('dialogs.congratulations.ifYouHaveCompletedConceptChapter') }}
              </li>
            </ul>
            <!-- /List -->

          </div>
        </div>
        <!-- /Dialog Body -->

        <!-- Continue Button -->
        <div class="dialog-footer">
          <el-button class="full" @click="finishResearchStep">
            {{ $t('dialogs.congratulations.continue') }}
          </el-button>
        </div>
        <!-- /Continue Button -->

      </div>
      <!-- /Background Wrapper -->

    </el-dialog>
    <!-- /Dialog -->

  </div>
</template>

<script>
export default {
  name: 'DialogCongratulationsResearch',
  props: {
    dialogCongratulationsResearchVisible: Boolean
  },

  methods: {
    finishResearchStep () {
      this.$emit('finish-research-step')
    }
  }
}
</script>
